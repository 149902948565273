import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Http, ResponseContentType, RequestOptions } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
// @Injectable({
//   providedIn: 'root'
// })
export class CommonService {

  constructor(private http: HttpClient, private httpnew: Http) {
  }

  uploadMedia(): any {
    // return this.http.get(environment.API_ENDPOINT + 'brandViewFront');
  }

  loadApplication(): any {
    return this.http.get(environment.API_ENDPOINT + environment.loadApplication)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  loadRegions(): any {
    return this.http.get(environment.API_ENDPOINT + environment.loadRegions)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  loadMachines(): any {
    return this.http.get(environment.API_ENDPOINT + environment.loadMachines)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  listTeams(): any {
    return this.http.get(environment.API_ENDPOINT + environment.TeamList)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  loadProvider(): any {
    return this.http.get(environment.API_ENDPOINT + environment.loadProfile)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  CreateTest(value: any, type: string): any {
    const endPoint = (type === 'Generic') ? environment.TestGenericCreate : environment.TestCreate;
    return this.http.post(environment.API_ENDPOINT + endPoint, value, { observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  /** Admin Create Team start  */
  createTeam(value: any): any {
    const endPoint = environment.createTeam;
    return this.http.post(environment.API_ENDPOINT + endPoint, value)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  createApplications(value: any): any {
    const endPoint = environment.createApplication;
    return this.http.post(environment.API_ENDPOINT + endPoint, value)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  createUsecases(value: any): any {
    const endPoint = environment.createUsecase;
    return this.http.post(environment.API_ENDPOINT + endPoint, value)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  createLoad(value: any): any {
    const endPoint = environment.createLoads;
    return this.http.post(environment.API_ENDPOINT + endPoint, value)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }
  /** Admin Create Team end  */

  ScheduleTest(value): any {
    // const boundary = '-----------------------------' + Math.floor(Math.random() * Math.pow(10, 8));
    const httpHeaders = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data; boundary=' + boundary,
    });
    return this.http.post(environment.API_ENDPOINT + environment.TestJMeterCreate, value, { headers: httpHeaders, observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  testResult(value): any {
    let params = new HttpParams();
    params = params.append('page', value.page);
    params = params.append('size', value.size);

    return this.http.get(environment.API_ENDPOINT + environment.TestResult, { params })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  testFilterResult(value): any {
    let params = new HttpParams();
    params = params.append('page', value.page);
    params = params.append('size', value.size);
    params = params.append('startdt', value.startdt);
    params = params.append('enddt', value.enddt);
    params = params.append('status', value.status);
    params = params.append('teamId', value.teamId);
    params = params.append('applicationId', value.applicationId);
    params = params.append('usecaseId', value.usecaseId);
    return this.http.get(environment.API_ENDPOINT + environment.TestResult, { params })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  healthResult(): any {
    const apiUrl = './assets/data/api/health.json';
    return this.http.get(apiUrl)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  ViewDetails(value: any): any {
    return this.http.get(environment.API_ENDPOINT + environment.Tests + '/' + value.testID + '/testjobs', { observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  DeleteTest(value: any): any {
    return this.http.delete(environment.API_ENDPOINT + environment.Tests + '/' + value.testID, { observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  DownloadFile(url: string): Observable<Blob> {
    return this.httpnew.get(url, { responseType: ResponseContentType.Blob })
      .pipe(map(res => {
        return new Blob([res.blob()], { type: 'application/csv' });
      }));
  }

  Login(value: any): any {
    const formData = new FormData();
    formData.append('username', value.ItCode);
    formData.append('password', value.password);
    const apiUrl = environment.API_ENDPOINT + environment.Login;
    const httpOptions = {
      withCredentials: true,
      observe: 'response' as 'response'
    };
    return this.http.post(apiUrl, formData, httpOptions)
      .pipe(map((response: any) => {
        return response;
      }),
        catchError((err) => {
          return throwError(err);
        }));
  }

  Logout(): any {
    return this.http.get(environment.API_ENDPOINT + 'logout', { observe: 'response' })
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

}
interface Resultset {
  status: number;
  message: string;
  responseType: ResponseType;
  result: any;
}
