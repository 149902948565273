import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-admin-dialog',
  templateUrl: './admin-dialog.component.html',
  styleUrls: ['./admin-dialog.component.css'],
})
export class AdminDialogComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('addAdminForm') private formDirective: NgForm;
  @Output() masterValueEventchange = new EventEmitter<any>();
  @Input() lblTitle: string;
  @Input() responseArr: [];
  @Input() passTeam: string;
  @Input() passApp: string;
  @Input() passUsecase: string;
  @Input() passLoad: string;
  @Input() selectApplicationArr: [];
  @Input() selectUsecaseArr: [];
  @Input() selectLoadArr: [];

  message: [];
  finalValueArr: any;
  finalTeamArr: [];
  finalAppArr: [];
  finalusecaseArr: [];
  val: string;
  teamValue: string;
  applicationValue: string;
  usecaseValue: string;
  teamDescValue: string;
  applicationDescValue: string;
  usecaseDescValue: string;
  loadValue: string;
  loadDescValue: string;
  errExists: string;
  adminForm: FormGroup;
  repoBranch: string;
  projectId: string;

  constructor(public fb: FormBuilder) {
    this.val = this.lblTitle;
    this.adminForm = this.fb.group({
      groupNameAdd: ['', [Validators.required]],
      repoBranch: ['', [Validators.required]],
      projectId: ['', [Validators.required]],
      groupDescription: [''],
    });
  }

  ngOnInit() {
    this.errExists = '';
    // tslint:disable-next-line:max-line-length
    this.finalValueArr = {
      teams: {
        name: '', description: '',
        applications: {
          name: '',
          description: '',
          usecases: {
            name: '',
            description: '',
            repoBranch: '',
            projectId: '',
            loads: { name: '', description: '' }
          }
        }
      }
    };
    this.teamValue = '';
    this.teamDescValue = '';
    this.applicationValue = '';
    this.applicationDescValue = '';
    this.usecaseValue = '';
    this.usecaseDescValue = '';
    this.repoBranch = '';
    this.projectId = '';
    this.adminForm.controls.groupNameAdd.setValue('');
  }

  cancelDialog() {
    this.adminForm.controls.groupNameAdd.setValue('');
    this.adminForm.controls.groupDescription.setValue('');
    this.closeModal();
  }

  closeModal(): void {
    this.closeBtn.nativeElement.click();
  }

  saveFrom(e) {
    this.errExists = '';
    if (this.lblTitle.toLowerCase( ) === 'usecase') {
      this.adminForm.get('repoBranch').setValidators([Validators.required]);
      this.adminForm.get('repoBranch').updateValueAndValidity();
      this.adminForm.get('projectId').setValidators([Validators.required]);
      this.adminForm.get('projectId').updateValueAndValidity();
    } else {
      this.adminForm.get('repoBranch').setValidators([]);
      this.adminForm.get('repoBranch').updateValueAndValidity();
      this.adminForm.get('projectId').setValidators([]);
      this.adminForm.get('projectId').updateValueAndValidity();
    }

    if (this.adminForm.status === 'VALID') {
      if (this.lblTitle === 'Team') {
        this.teamValue = e.value.groupNameAdd;
        this.teamDescValue = e.value.groupDescription;
        const getApp = _.find(this.responseArr, function (team: any) {
          return team.name.toLowerCase() === e.value.groupNameAdd.toLowerCase();
        });
        if (getApp !== undefined) {
          this.errExists = 'Team Name Already Exists';
        } else {
          if (this.passTeam !== '') {
            this.finalValueArr.teams.name = this.passTeam;
            this.finalValueArr.teams.description = '';
          } else {
            this.finalValueArr.teams.name = this.teamValue;
            this.finalValueArr.teams.description = this.teamDescValue;
          }
          this.masterValueEventchange.next({ type: this.lblTitle, value: this.teamValue, description: this.teamDescValue });
        }
      } else if (this.lblTitle === 'Application') {
        let getApplication = '';
        if (this.selectApplicationArr.length > 1) {
          getApplication = _.find(this.selectApplicationArr, function (application: any) {
            return application.label.toLowerCase() === e.value.groupNameAdd.toLowerCase();
          });
        }
        if (getApplication === undefined || getApplication === '') {
          if (this.passTeam === '' || this.passApp === '') {
            this.applicationValue = e.value.groupNameAdd;
            this.applicationDescValue = e.value.groupDescription;
            this.finalValueArr.teams.applications.name = this.applicationValue;
            this.finalValueArr.teams.applications.description = this.applicationDescValue;
          } else {
            this.finalValueArr.teams.name = this.passTeam;
            this.finalValueArr.teams.description = '';
          }
          this.masterValueEventchange.next({ type: this.lblTitle, value: e.value.groupNameAdd, description: e.value.groupDescription });
        } else {
          this.errExists = 'Application Name already Exists';
        }
      } else if (this.lblTitle === 'Usecase') {
        this.usecaseValue = e.value.groupNameAdd;
        this.usecaseDescValue = e.value.groupDescription;
        this.repoBranch = e.value.repoBranch;
        this.projectId = e.value.projectId;
        let getUsecase = '';
        if (this.selectUsecaseArr.length > 1) {
          getUsecase = _.find(this.selectUsecaseArr, function (ucase: any) {
            return ucase.label.toLowerCase() === e.value.groupNameAdd.toLowerCase();
          });
        }
        if (getUsecase === undefined || getUsecase === '') {
          if (this.passApp !== '') {
            this.finalValueArr.teams.applications.name = this.passApp;
            this.finalValueArr.teams.applications.description = '';
          }
          if (this.passTeam === '' || this.passUsecase === '') {
            this.finalValueArr.teams.applications.usecases.name = this.usecaseValue;
            this.finalValueArr.teams.applications.usecases.description = this.usecaseDescValue;
            this.finalValueArr.teams.applications.usecases.repoBranch = this.repoBranch;
            this.finalValueArr.teams.applications.usecases.projectId = this.projectId;
          }
          this.masterValueEventchange.next({ type: this.lblTitle, value: this.usecaseValue, description: this.usecaseDescValue,
                                              repoBranch: this.repoBranch, projectId: this.projectId });
        } else {
          this.errExists = 'Usecase Name already Exists';
        }
      } else if (this.lblTitle === 'Load') {
        this.loadValue = e.value.groupNameAdd;
        this.loadDescValue = e.value.groupDescription;
        let getLoads = '';
        if (this.selectLoadArr.length > 1) {
          getLoads = _.find(this.selectLoadArr, function (loads: any) {
            return loads.label.toLowerCase() === e.value.groupNameAdd.toLowerCase();
          });
        }

        if (getLoads === undefined || getLoads === '') {
          if (this.passTeam !== '') {
            this.finalValueArr.teams.name = this.passTeam;
            this.finalValueArr.teams.description = '';
          }
          if (this.passApp !== '') {
            this.finalValueArr.teams.applications.name = this.passApp;
            this.finalValueArr.teams.applications.description = '';
          }
          if (this.passUsecase !== '') {
            this.finalValueArr.teams.applications.usecases.name = this.passUsecase;
            this.finalValueArr.teams.applications.usecases.description = '';
            this.finalValueArr.teams.applications.usecases.repoBranch = this.repoBranch;
            this.finalValueArr.teams.applications.usecases.projectId = this.projectId;
          }
          if (this.passTeam === '' || this.passLoad === '') {
            this.finalValueArr.teams.applications.usecases.loads.name = this.loadValue;
            this.finalValueArr.teams.applications.usecases.loads.description = this.loadDescValue;
          }
          this.masterValueEventchange.next({ type: this.lblTitle, value: e.value.groupNameAdd, description: e.value.groupDescription });
        } else {
          this.errExists = 'Load already Exists';
        }
      }
      this.message = this.finalValueArr;
      if (this.errExists === '') {
        this.formDirective.resetForm();
        this.cancelDialog();
      }
    }
  }
}
