// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  staging: false,
  TITLE: 'PTAAS-Performance Test As A Service',
  API_ENDPOINT: 'https://beast-api.mystiquex.com/ptaas/v1/',
  //API_ENDPOINT: 'http://localhost:8080/ptaas/v1/',
  loadApplication: 'teams/3/applications',
  loadRegions: 'regions',
  loadMachines: 'machinetypes',
  TeamList: 'teams',
  Tests: 'tests',
  TestCreate: 'tests/enterprise/create',
  TestJMeterCreate: 'tests/jmeter/create',
  TestGenericCreate: 'tests/generic/create',
  TestResult: 'tests/summary',
  CUSTOM_LOAD_RPS_VALUE_MIN: '0',
  CUSTOM_LOAD_RPS_VALUE_MAX: '10000',
  TEST_DURATION_VALUE_MIN: '30',
  TEST_DURATION_VALUE_MAX: '10000',
  IMAGE_UPLOAD_TYPE_JMX: ['jmx'],
  IMAGE_UPLOAD_TYPE_CSV: ['text/xlsx', 'text/xls', 'text/csv', 'application/vnd.ms-excel'],
  MAX_UPLOAD_FILES_JMX: '5',
  MAX_UPLOAD_FILES_SIZE_JMX: '157286400', //  157286400 150MB '2097152', // 2MB
  IMAGE_UPLOAD_MAX_NO_FILE_CSV: '25',
  loadProfile: 'profiles',
  createTeam: 'admin/teams/create',
  createApplication: 'admin/applications/create',
  createUsecase: 'admin/usecases/create',
  createLoads: 'admin/loads/create',
  url: 'https://moto-performance-test.s3-us-west-2.amazonaws.com/JMeter/jmeter-reports',
  Login: 'login',
  //domain: 'dev.cloud.lenovo.com',
  domain: 'localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
